import React from 'react';

import SearchResult from './SearchResult';

class Search extends React.Component {
    render() {
        return (
            <div>
                <div className="row justify-content-center">
                    <div className="col text-white">{this.props.searchResults.length} results found.</div>
                </div>
                <div className="row justify-content-center pb-3">
                    <div className="col">
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <div className="list-group">
                                    {
                                        this.props.searchResults.map((value, index) => {
                                            return <SearchResult key={index} date={value.date} text={value.text} isTask={value.isTask} isComplete={value.isComplete} clickSearchResult={this.props.clickSearchResult}/>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Search;
