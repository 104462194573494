import React from "react"

export default class Task extends React.Component{
  constructor(props) {
      super(props);
      this.handleCloseTask = this.handleCloseTask.bind(this);
  }

  render(){
    return(
      <span className="list-group-item task-list-task">
        {this.props.name}
        <button className="btn btn-sm btn-warning" onClick = {this.handleCloseTask} style={{float:"right"}}>X</button>
      </span>
    )
  }

  handleCloseTask(){
      this.props.onClose(this.props.id);
  }
}