import React from 'react';
import axios from 'axios';
import { getToken } from './Token';

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.input = {};
        this.state = {
            errorMessage: '',
            oldPassword: '',
            newPassword: '',
            showPasswordChangeConfirmation: false
        };
    }
    render() {
        return (
            <div className="container-fluid buttons login-form pt-4">
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-4">
                        <form>
                            {this.state.errorMessage && <div className="mb-2 text-warning">{this.state.errorMessage}</div>}
                            {this.state.showPasswordChangeConfirmation && <div className='text-success text-center mb-4'>Password updated.</div>}
                            <div className="form-group">
                                <input name="oldPassword" type="password" className="form-control" id="oldPassword" placeholder="Enter old password"
                                value={this.state.oldPassword}
                                onChange={this.handleInputChange}
                                required/>
                            </div>
                            <div className="form-group">
                                <input name="newPassword" type="password" className="form-control" id="newPassword" placeholder="Enter new password"
                                value={this.state.newPassword}
                                onChange={this.handleInputChange}
                                required/>
                            </div>
                            <button type="submit" className="btn btn-primary login-button" onClick={this.handlePasswordChange}>Update Password</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    handlePasswordChange = (event) => {
        event.preventDefault();
        axios.post('/api/passwordchange',
            this.state,
            {
                headers: { 'Authorization': `Bearer ${getToken()}` }
            })
        .then(res => {
            if (res.status === 200) {
                this.setState({ errorMessage: '' });
                this.setState({ oldPassword: '', newPassword: '', showPasswordChangeConfirmation: true });
            } else {
                const error = new Error(res.error);
                throw error;
            }
        })
        .catch(err => {
            if (err.response && err.response.data) {
                this.setState({ errorMessage: err.response.data });
            }
        });
    }
    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value
        });
    }
}

export default Register;
