import React, {Component} from "react"
//Components
import Bookmark from "./Bookmark"

export default class BookmarkContainer extends Component{
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      description: ''
    }
    this.handleDeleteBookmark = this.handleDeleteBookmark.bind(this);
    this.handleAddBookmark = this.handleAddBookmark.bind(this);
    this.checkEnterKey = this.checkEnterKey.bind(this);
  }

  render(){
    return(
      <div>
        <div className= "row mb-3">
            <div className = "col-12 col-sm-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Description"
                    onChange = {e => this.updateDescription(e)}
                    value = {this.state.description}
                    onKeyPress = {e => this.checkEnterKey(e)}
                    />
            </div>
            <div className = "col-12 col-sm-8">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Page URL"
                    onChange = {e => this.updateUrl(e)}
                    value = {this.state.url}
                    onKeyPress = {e => this.checkEnterKey(e)}
                    />
            </div>
            <div className="col-12">
                <button type="button" className="col-sm-2 btn btn-success task-button mt-3" onClick={this.handleAddBookmark}> Save New Bookmark </button>
                <button type="button" className="col-sm-2 btn btn-primary task-button mt-3" onClick={() => this.props.history.push('/')}> Back To Notes </button>
            </div>
        </div>
        <div className="row justify-content-center">
          <div className = "col">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="list-group">
                      {
                        this.props.state.bookmarkList.map((value, index) => {
                            if (value && !value.complete) {
                                return <Bookmark
                                    key={index}
                                    id={value._id}
                                    description={value.description}
                                    url={value.url}
                                    onDeleteBookmark={this.handleDeleteBookmark}
                                />
                            }
                            return null;
                        })
                      }
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleDeleteBookmark(id){
    this.props.removeBookmark(id)
  }

  checkEnterKey(e){
    var keyCode = e.which || e.keyCode;
    if(keyCode === 13) {
        this.props.addBookmark(this.state.url, this.state.description);
        this.clearBookmarkForm();
    }
  }

  updateDescription(e){
      this.setState({ description: e.target.value });
  }

  updateUrl(e){
    this.setState({ url: e.target.value });
  }

  handleAddBookmark(e){
    this.props.addBookmark(this.state.url, this.state.description);
      this.clearBookmarkForm();
  }

  clearBookmarkForm() {
      this.setState({ url: '', description: '' });
  }
}