import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
class ForgotPassowrd extends React.Component {
    constructor(props) {
        super(props);
        var email = props.email;
        this.state = {
            errorMessage: '',
            showConfirmation: false,
            email: email
        };
    }
    render() {
        return (
            <div className="container-fluid buttons login-form pt-4">
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-4">
                        {this.state.showConfirmation && <div className='text-success text-center mb-4'>Check your email for a password reset.</div>}
                        <form>
                            {this.state.errorMessage && <div className="mb-2 text-warning">{this.state.errorMessage}</div>}
                            <div className="form-group">
                                <input name="email" type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                    required />
                            </div>
                            <button type="submit" className="btn btn-primary login-button" onClick={this.resetPassword}>Reset Password</button>
                            <Link className="btn btn-link text-success pl-0" to="/login">Login here</Link>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    resetPassword = (event) => {
        event.preventDefault();
        axios.post('/api/passwordreset', this.state)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ errorMessage: '' });
                    this.setState({ showConfirmation: true });
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    this.setState({ errorMessage: err.response.data });
                }
            });
    }
    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value
        });
    }
}

export default ForgotPassowrd;
