import React from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import simpleDate from './SimpleDate';
import {getToken} from './Token';

class Export extends React.Component {
    constructor(props) {
        super(props);
        this.fromDate = '';
        this.toDate = '';
        this.state = {
            error: ''
        };
    }

    render() {
        
        return (
            <div className="export-page">
                <div className="row">
                    <div className="col mt-3 mb-3">
                        <div className="h5">Export Notes</div>
                        <p className="text-white">Select "from" and "to" dates to download all notes made in that time period.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-sm-6 col-lg-6">
                        <div className="h5 text-center">From</div>
                        <div className="d-flex justify-content-center">
                            <Calendar onChange={this.fromChange}></Calendar>
                        </div>
                    </div>
                    <div className="col-12 d-block d-sm-none mt-3">&nbsp;</div>
                    <div className="col col-sm-6 col-lg-6">
                        <div className="h5 text-center">To</div>
                        <div className="d-flex justify-content-center">
                            <Calendar onChange={this.toChange}></Calendar>
                        </div>
                    </div>
                    <div className="col pt-5 d-flex justify-content-center">
                        <div className="d-flex flex-column col-10 col-sm-5 col-xl-3">
                            <button className="btn btn-success" onClick={this.exportNotes}>Export/Download notes</button>
                            <button className="btn btn-primary mt-2" onClick={() => this.props.history.push('/')}>Cancel</button>
                            <div className="mt-2 text-warning">{this.state.error}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    fromChange = (newDate) => {
        this.fromDate = newDate;
    }

    toChange = (newDate) => {
        this.toDate = newDate;
    }

    exportNotes = () => {
        if (this.fromDate && this.toDate) {
            this.setState({ error: '' });
            axios.post("/api/exportNotes", {
                from: simpleDate.simpleDate(this.fromDate),
                to: simpleDate.simpleDate(this.toDate),
                responseType: 'blob'
            },
                {
                    headers: { 'Authorization': `Bearer ${getToken()}` }
                }).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'exported.txt'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
        } else {
            this.setState({ error: 'Please select both "to" and "from" dates.' });
        }
    }
}


export default Export;