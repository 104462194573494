import React from 'react';
import simpleDate from './SimpleDate';

class SearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickResult = this.handleClickResult.bind(this);
    }

    render() {
        if (!this.props.isTask) {
            return <span className="list-group-item search-result" onClick={this.handleClickResult}>{this.props.text} 
                <i className="float-right">{simpleDate.simpleDateToHyphenSeparated(this.props.date)}</i>
            </span>
        } else {
            if (this.props.isComplete) {
                return <span className="list-group-item"><s>{this.props.text}</s> <i className="float-right"> Task - complete</i></span>
            } else {
                return <span className="list-group-item">{this.props.text}<i className="float-right"> Task - incompete</i></span>
            }
        }
    }

    handleClickResult() {
        this.props.clickSearchResult(this.props.date);
    }
}

export default SearchResult;
