import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            emailReg: '',
            passwordReg: '',
            passwordRegConf: '',
            showRegThanks: false,
        };
    }
    render() {
        return (
            <>
            {this.state.showRegThanks && <div className='text-success text-center mb-4'>Thanks for registering, sign-in to continue.</div>}
            
            <div className="container-fluid login-form">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-lg-6">
                        <h1 className="display-1 text-white">A super-simple work logger.</h1>
                        <p className="lead mt-4 col-12 col-sm-10 pl-0 text-white">HumanLogs is a place to keep track of the work you do each day.  It's free and could come in handy when filling in timesheets or updating a r&eacute;sum&eacute;.</p>
                    </div>

                    <div className="d-flex d-lg-none row justify-content-center mt-4">
                        <div className="col-10 col-sm-8 demo-gif text-center">
                            <img src="demo.gif" alt="Demo of humanlogs logging and task creation" />
                        </div>
                    </div>

                    <div className="pb-5 pt-5 col-12 col-sm-10 col-md-6 col-lg-4">
                        {// spacer
                            <div className="d-none d-lg-block mt-2"></div>
                        }
                        <p className="text-white">Sign-up for a free account now:</p>
                        <form>
                            {this.state.errorMessage && <div className="mb-2 text-warning">{this.state.errorMessage}</div>}
                            <div className="form-group">
                                <input name="emailReg" type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"
                                value={this.state.emailReg}
                                onChange={this.handleInputChange}
                                required/>
                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                            </div>
                            <div className="form-group">
                                <input name="passwordReg" type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"
                                value={this.state.passwordReg}
                                onChange={this.handleInputChange}
                                required/>
                            </div>
                            <div className="form-group">
                                <input name="passwordRegConf" type="password" className="form-control" id="exampleInputPassword1" placeholder="Confirm password"
                                value={this.state.passwordRegConf}
                                onChange={this.handleInputChange}
                                required/>
                            </div>
                            <button type="submit"className="btn btn-primary login-button" onClick={this.handleRegisterSubmit}>Register</button>
                            <Link className="btn btn-link text-white pl-0" to="/login">Already signed up? <span className="text-success">Login here.</span></Link>
                        </form>
                    </div>
                </div>
                <div className="d-none d-lg-flex row justify-content-center mt-4 pb-4">
                    <div className="col-10 col-sm-8 demo-gif text-center">
                        <img src="demo.gif" alt="Demo of humanlogs logging and task creation"/>
                    </div>
                </div>
            </div>
            </>
        );
    }

    handleRegisterSubmit = (event) => {
        event.preventDefault();
        axios.post('/api/register', this.state)
        .then(res => {
          if (res.status === 200) {
            this.setState({
                errorMessage: '',
                showRegThanks: true,
                emailReg: '',
                passwordReg: '',
                passwordRegConf: '' 
              });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch(err => {
          if (err.response && err.response.data) {
            this.setState({ errorMessage: err.response.data });
          }
        });
    }

    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value
        });
    }
}

export default Home;
