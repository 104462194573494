import React from 'react';
import axios from 'axios';
import { Route, withRouter } from 'react-router-dom';
import './App.css';

import NoteScreen from './NoteScreen';
import Home from './Home';
import Privacy from './Privacy';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import PasswordChange from './PasswordChange';
import Nav from './Nav';
import Search from './Search';
import { getToken, setToken } from './Token';
import simpleDate from './SimpleDate';
import Export from './Export';
import pages from './Pages';
import BookmarkContainer from './BookmarkContainer';

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.subtractDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() - days);
    return date;
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            currentScreen: '',
            taskList: [],
            bookmarkList: [],
            today: simpleDate.simpleDate(new Date()) || '',
            todaysData: {},
            userData: {},
            searchResults: [],
            email: '',
            password: '',
            errorMessage: ''
        };
    }
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <Nav handleSearch={this.handleSearch}
                        logout={this.handleLogout}
                        handleClickCalendar={this.handleClickCalendar}
                        state={this.state}
                        handleClearSearch={this.handleClearSearch}/>
                </header>
                <div className="container-fluid">
                    <Route exact path="/" render={()=> {
                        if (this.state.currentScreen === pages.NOTES) {
                            return <NoteScreen state={this.state} 
                            removeTask={this.handleRemoveTask}
                            addTask={this.handleAddTask}
                            noteSave={this.handleNoteSave}
                            updateDay={this.updateDay}
                            noteChange={this.handleNoteChange}/> 
                        } else if (this.state.currentScreen === pages.HOME) {
                            return <Home state={this.state} />
                        } else if (this.state.currentScreen === pages.SEARCH) {
                            return <Search handleSearch={this.handleSearch} 
                                searchResults={this.state.searchResults} 
                                clickSearchResult={this.handleClickSearchResult} />
                        }
                    }}/>

                    <Route path="/privacy" component={Privacy} />
                    <Route path="/password-change" render={() => <PasswordChange /> }/>
                    <Route path="/login" render={()=> <Login state={this.state}
                        login={this.handleLoginSubmit}
                        inputChange={this.handleGenericInputChange}/>} />
                    <Route path="/forgot-password" render={()=> <ForgotPassword state={this.state} email={this.state.email}/>} />
                    <Route path="/export" render={()=> <Export state={this.state} history={this.props.history}/>} />
                    <Route path="/bookmarks" render={()=> <BookmarkContainer state={this.state} 
                        inputChange={this.handleGenericInputChange}
                        addBookmark={this.handleAddBookmark}
                        removeBookmark={this.handleRemoveBookmark}
                        history={this.props.history}/>} />
                </div>
            </div>
        );
    }

    handleClickCalendar = (date) => {
        this.setState({
            today: simpleDate.simpleDate(date),
            todaysData: this.state.userData[simpleDate.simpleDate(date)] || { 'notes': '' },
        });
        this.props.history.push('/');
    }

    handleRemoveTask = (id) => {
        let taskUpdates = this.state.taskList;
        const index = taskUpdates.findIndex(el => el._id === id)
        taskUpdates[index].complete = true;

        axios.post("/api/deleteTask", {
            id: id
        },
        { headers: { 'Authorization': `Bearer ${getToken()}` } 
        }).then(response => {
            if (response.data.success) {
                this.setState({ taskList: taskUpdates });
            }
        });
    }

    handleAddTask = (name) => {
        if (name) {
            let tmp = this.state.taskList;
            axios.post("/api/addTask", {
                description: name
            },
            { headers: { 'Authorization': `Bearer ${getToken()}` } 
            }).then(response => {
                if (response.data.success) {
                    tmp.push(response.data.newTask);
                    this.setState({ taskList: tmp })
                }
            });
        }
    }

    handleRemoveBookmark = (id) => {
        let bookmarkUpdates = this.state.bookmarkList;
        const index = bookmarkUpdates.findIndex(el => el._id === id)
        bookmarkUpdates.splice(index, 1);

        axios.post("/api/deleteBookmark", {
            id: id
        },
        { headers: { 'Authorization': `Bearer ${getToken()}` } 
        }).then(response => {
            if (response.data.success) {
                this.setState({ bookmarkList: bookmarkUpdates });
            }
        });
    }

    handleAddBookmark = (url, description) => {
        if (url && description) {
            let tmp = this.state.bookmarkList;
            axios.post("/api/addBookmark", {
                description: description,
                url: url
            },
            { headers: { 'Authorization': `Bearer ${getToken()}` } 
            }).then(response => {
                if (response.data.success) {
                    tmp.push(response.data.newBookmark);
                    this.setState({ bookmarkList: tmp })
                }
            });
        }
    }

    handleNoteSave = (event) => {
        const todaysDate = this.state.today;
        let currentState = this.state.userData;
        currentState[todaysDate] = {
            date: todaysDate,
            notes: event.target.value
        };

        this.setState({
            userData: currentState
        });

        axios.post("/api/updateData", {
            date: this.state.today,
            notes: event.target.value
        },
            {
                headers: { 'Authorization': `Bearer ${getToken()}` } 
        }).then(response => {

        });
    }

    updateDay = (isNext) => {
        var todaysDate = simpleDate.simpleDateToDate(this.state.today);
        let newDate = isNext ? todaysDate.addDays(1) : todaysDate.subtractDays(1);
        this.setState({ today: simpleDate.simpleDate(newDate), todaysData: this.state.userData[simpleDate.simpleDate(newDate)] || { 'notes': '' } });
    }

    handleNoteChange = (event) => {
        this.setState({
            todaysData: {
                notes: event.target.value
            }
        });
    }

    handleSearch = (event) => {
        let resultsFound = [];
        if (event.target.value) {
            event.target.classList.add('populated');
            const notes = Object.values(this.state.userData);
            notes.forEach((note) => {
                if (note.notes && note.date) {
                    if (note.notes.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1) {
                        resultsFound.push({
                            text: note.notes,
                            date: note.date,
                            isTask: false
                        });
                    }
                }
            });
            this.state.taskList.forEach((task) => {
              if (task.description.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1) {
                resultsFound.push({
                  text: task.description,
                  isTask: true,
                  isComplete: !!task.complete,
                  completedDate: task.completedDate || ''
                });
              }
            });
            this.setState({
                currentScreen: pages.SEARCH,
                searchResults: resultsFound
            });
        } else {
            event.target.classList.remove('populated');
            this.setState({
                currentScreen: pages.NOTES,
                searchResults: []
            });
        }
    }

    handleClearSearch = (e) => {
        let searchInput;
        if (e.target.nodeName === 'svg') {
            searchInput = e.target.parentElement.previousSibling;
        } else if (e.target.nodeName === 'line') {
            searchInput = e.target.parentElement.parentElement.previousSibling;
        } else {
            searchInput = e.target.previousSibling;
        }
        searchInput.value = '';
        searchInput.classList.remove('populated');
        this.setState({currentScreen: pages.NOTES});
    }

    handleClickSearchResult = (date) => {
        let newState = {
            currentScreen: pages.NOTES,
            today: date
        };

        let todaysDataFound = this.state.userData[date];
        if (todaysDataFound && todaysDataFound.notes) {
            newState['todaysData'] = todaysDataFound;
        }
        this.setState(newState);
    }

    handleGenericInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value
        });
    }

    handleLoginSubmit = (event) => {
      event.preventDefault();
      axios.post('/api/authenticate', this.state)
      .then(res => {
        if (res.status === 200) {
          this.setState({ errorMessage: '' });
          setToken(res.data.token);
          this.loadData(() => {
              console.log('ffsdf');
                this.setState({ currentScreen: pages.NOTES, isLoggedIn: true });
                this.props.history.push("/");
            });
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch(err => {
        if (err.response && err.response.data) {
          this.setState({errorMessage: err.response.data});
        }
      });
    }

    handleLogout = () => {
        axios.get("/logout", {
            headers: { 'Authorization': `Bearer ${getToken()}` }
        }).then(response => {
            this.setState({ currentScreen: pages.HOME, password: '', email: '', userData: {}, taskList: [], isLoggedIn: false });
            document.cookie = "cookiename= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            localStorage.removeItem('humanlogstoken')
            sessionStorage.removeItem('humanlogstoken')
            this.props.history.push('/');
      });
    }

    loadData = (callback) => {
        axios.get("/api/getTasks", {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        }).then(response => {
            let taskList = [];
            if (response.data.success && response.data.data) {
                response.data.data.forEach(task => {
                    taskList.push(task);
                });
            }
            this.setState({ taskList: taskList || [] });
        });
        axios.get("/api/getBookmarks", {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        }).then(response => {
            let bookmarkList = [];
            if (response.data.success && response.data.data) {
                response.data.data.forEach(bookmark => {
                    bookmarkList.push(bookmark);
                });
            }
            this.setState({ bookmarkList: bookmarkList || [] });
        });

        axios.get("/api/getData", { headers: { 'Authorization': `Bearer ${getToken()}` } })
            .then(response => {
                let allData = {};

                if (response.data && response.data.data) {
                    response.data.data.forEach(day => {
                        allData[day.date] = day;
                    });
                } else {
                    allData[this.state.today] = '';
                }

                const todaysData = allData[this.state.today];
                this.setState({ userData: allData, todaysData: todaysData || {} });
                callback();
            });
    }

    componentDidMount() {
        axios.get('/checkToken', { headers: { 'Authorization': `Bearer ${getToken()}` } })
        .then(res => {
          if (res.status === 200) {
            this.loadData(() =>
              this.setState({ currentScreen: pages.NOTES, email: res.data.email, isLoggedIn: true })
            );
          } else {
            this.setState({ currentScreen: pages.HOME });
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ currentScreen: pages.HOME });
        });
    }
}

export default withRouter(App);