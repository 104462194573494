import React from 'react';
import './App.css';

import Day from './Day';
import Button from './Button';
import ListTaskContainer from './ListTaskContainer';
import CreateTaskForm from './CreateTaskForm';

class NoteScreen extends React.Component {
    render() {
        return (
            <div className="container-fluid">
                    <div className="buttons">
                      <div className="row">
                          <div className="col">
                              <Button direction={-1} updater={this.props.updateDay}/>
                              <Button direction={1} updater={this.props.updateDay}/>
                          </div>
                      </div>
                    </div>
                {this.props.state.todaysData && <Day state={this.props.state} noteChange={this.props.noteChange} noteSave={this.props.noteSave}/>}
                <CreateTaskForm addTask={this.props.addTask}/>
                <ListTaskContainer list={this.props.state.taskList} removeTask={this.props.removeTask}/>
            </div>
        );
    }
}

export default NoteScreen;
