import React from 'react';

class Button extends React.Component {
    constructor(props) {
        super(props);
        var isNext = this.props.direction === 1;
        var direction = isNext ? ">" : "<";
        this.state = {
            isNext: isNext,
            direction: direction
        };

        this.nextOrPrevious = this.nextOrPrevious.bind(this);
    }
    render() {
        var directionClass = this.state.isNext ? 'button-right float-right' : 'button-left float-left';
        return (
            <button className={'btn btn-primary ' + directionClass} onClick={this.nextOrPrevious}>
                {this.state.direction}
            </button>
        )
    }
    nextOrPrevious(ev) {
        this.props.updater(this.state.isNext);
    }
}

export default Button;