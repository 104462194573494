import React, {Component} from "react"
export default class CreateTaskForm extends Component{

  constructor(props){
    super(props)
    this.state = {
      taskName: ''
    }
      this.handleAddTask = this.handleAddTask.bind(this);
      this.checkEnterKey = this.checkEnterKey.bind(this);
  }

  render(){
    return(
        <div className= "row mb-3">
            <div className = "col-12 col-sm-10">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter new tasks"
                        onChange = {e => this.updateTaskName(e)}
                        value = {this.state.taskName}
                        onKeyPress = {e => this.checkEnterKey(e)}
                        />
            </div>
            <div className="col-12 col-sm-2">
                <button type="button" className="btn btn-primary task-button" onClick={this.handleAddTask}> Create New Task </button>
            </div>
        </div>
    )
  }

  checkEnterKey(e){
    var keyCode = e.which || e.keyCode;
    if(keyCode === 13) {
        this.props.addTask(this.state.taskName);
        this.clearTaskName();
    }
  }

  updateTaskName(e){
      this.setState({ taskName: e.target.value });
  }

  handleAddTask(e){
    this.props.addTask(this.state.taskName);
      this.clearTaskName();
  }

  clearTaskName() {
      this.setState({ taskName: '' });
  }
}