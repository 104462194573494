import React from 'react';

class Note extends React.Component {

    render() {
        return <textarea className="form-control" type="text" value={this.props.state.todaysData.notes} onChange={(e) => this.props.noteChange(e)} onBlur={(e) => this.props.noteSave(e)} />
    }
    
}

export default Note;
