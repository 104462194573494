module.exports = {
    simpleDate: (date) => {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        let monthString = month.toString();
        let dayString = day.toString();

        monthString = month < 10 ? '0' + monthString : monthString;
        dayString = day < 10 ? '0' + dayString : dayString;

        return date.getFullYear().toString() + monthString + dayString;
    },
    simpleDateToHyphenSeparated: (simpleDate) => {
        return `${simpleDate.substr(0, 4)}-${parseInt(simpleDate.substr(4, 2))}-${simpleDate.substr(6, 2)}`;
    },
    simpleDateToDate: (simpleDate) => {
        return new Date(simpleDate.substr(0, 4), parseInt(simpleDate.substr(4, 2)) - 1, simpleDate.substr(6, 2));
    }
};