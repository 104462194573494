import React from "react"

export default class Bookmark extends React.Component{
  constructor(props) {
      super(props);
      this.handleDeleteTask = this.handleDeleteTask.bind(this);
  }

  render(){
    return(
      <span className="list-group-item task-list-task">
        {this.props.description} - <a href={this.props.url} rel="noopener noreferrer" target="_blank">{this.props.url}</a>
        <button className="btn btn-sm btn-primary" onClick = {this.handleDeleteTask} style={{float:"right"}}>X</button>
      </span>
    )
  }

  handleDeleteTask(){
      this.props.onDeleteBookmark(this.props.id);
  }
}