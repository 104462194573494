import React from 'react';
import { withRouter, Link } from 'react-router-dom';

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.props.state.errorMessage = '';
    }
    render() {
        return (            
            <div className="container-fluid buttons login-form pt-4">
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-4">
                        <form>
                            {this.props.state.errorMessage && <div className="mb-2 text-warning">{this.props.state.errorMessage}</div>}
                            <div className="form-group">
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"
                                    name="email"
                                    value={this.props.state.email}
                                    onChange={this.props.inputChange}
                                    required />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"
                                    name="password"
                                    value={this.props.state.password}
                                    onChange={this.props.inputChange}
                                    required />
                            </div>
                            <button type="submit" className="btn btn-primary login-button" onClick={this.props.login}>Login</button>
                            <Link className="btn btn-link btn-register text-success pl-0" to="/">Register here</Link>
                            <Link className="btn btn-link btn-forgot text-warning pr-0 float-right" to="/forgot-password">Forgot your password?</Link>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
