import React from 'react';
import Calendar from 'react-calendar';
import simpleDate from './SimpleDate';
import { withRouter, Link } from 'react-router-dom';

class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.input = {};
        this.state = {
            showCalendar: false
        };
    }

    render() {
        console.log(this.props.state);
        return (
            
            <nav className="navbar navbar-expand-sm navbar-dark mb-1">
                <span className="icon"></span>
                <a className="navbar-brand" href="/">HUMANLOGS</a>
                {this.props.state.currentScreen !== 'home' && this.props.state.currentScreen !== 'login' && this.props.state.currentScreen !== 'forgotpassword' &&
                    <button className="navbar-toggler" type="button" onClick={this.mobileNavToggle} data-toggle="collapse" data-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                }

                <div className="collapse navbar-collapse" id="navbar">
                    <ul className="navbar-nav ml-auto">
                        {(this.props.state.isLoggedIn ) &&
                        
                            <>
                            <li className="nav-item nav-search">
                                <button className="btn search-button" title="search">
                                    <svg viewBox="12 6 10 22">
                                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="icon-111-search">
                                                <path d="M19.4271164,21.4271164 C18.0372495,22.4174803 16.3366522,23 14.5,23 C9.80557939,23 6,19.1944206 6,14.5 C6,9.80557939 9.80557939,6 14.5,6 C19.1944206,6 23,9.80557939 23,14.5 C23,16.3366522 22.4174803,18.0372495 21.4271164,19.4271164 L27.0119176,25.0119176 C27.5621186,25.5621186 27.5575313,26.4424687 27.0117185,26.9882815 L26.9882815,27.0117185 C26.4438648,27.5561352 25.5576204,27.5576204 25.0119176,27.0119176 L19.4271164,21.4271164 L19.4271164,21.4271164 Z M14.5,21 C18.0898511,21 21,18.0898511 21,14.5 C21,10.9101489 18.0898511,8 14.5,8 C10.9101489,8 8,10.9101489 8,14.5 C8,18.0898511 10.9101489,21 14.5,21 L14.5,21 Z" id="search">
                                                </path>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                                    <form className="form-inline my-2 my-md-0" onSubmit={this.handleSubmit}>
                                        <input className="form-control" type="text" placeholder="Search" onChange={this.props.handleSearch} ref={c => (this.input = c)} />
                                        <span className="search-clear" onClick={(e) => this.props.handleClearSearch(e)}>
                                            <svg viewport="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <line x1="1" y1="11" x2="11" y2="1" strokeWidth="2"/>
                                                <line x1="1" y1="1" x2="11" y2="11" strokeWidth="2"/>
                                            </svg>
                                        </span>
                                    </form>
                            </li>
                            <li className="nav-item">
                                <button className="btn calendar-button" onClick={this.handleClickCalendarLink} title="View Calendar">
                                    <svg viewBox="0 1 14 14" style={{ fill: '#fff' }} version="1.1" aria-hidden="true">
                                        <path fillRule="evenodd" d="M13 2h-1v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H6v1.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5V2H2c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm0 
                                            12H2V5h11v9zM5 3H4V1h1v2zm6 0h-1V1h1v2zM6 7H5V6h1v1zm2 0H7V6h1v1zm2 0H9V6h1v1zm2 0h-1V6h1v1zM4 9H3V8h1v1zm2 0H5V8h1v1zm2 0H7V8h1v1zm2 0H9V8h1v1zm2 0h-1V8h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1zm2 
                                            0h-1v-1h1v1zm-8 2H3v-1h1v1zm2 0H5v-1h1v1zm2 0H7v-1h1v1zm2 0H9v-1h1v1z">
                                        </path>
                                    </svg>
                                    <span className='d-inline d-sm-none mob-link'>Show Calendar</span>
                                </button>
                                {this.state.showCalendar && <Calendar value={simpleDate.simpleDateToDate(this.props.state.today)} onChange={this.props.handleClickCalendar}/>}
                            </li>
                            <li className="nav-item">
                                <Link to="/bookmarks" className="btn bookmark-button" title="View Bookmarks">
                                    <svg viewBox="4 4 20 16" style={{ fill: '#fff' }} version="1.1" aria-hidden="true">
                                        <path d="M 7 2 C 5.355469 2 4 3.355469 4 5 L 4 19 C 4 20.644531 5.355469 22 7 22 L 20 22 L 20 20 L 7 20 C 6.433594 20 6 19.566406 6 19 C 6 18.433594 6.433594 18 7 18 L 20 18 L 20 2 Z M 7 4 L 10 4 L 10 12.125 L 11.574219 11.019531 L 13 10.019531 L 16 12.125 L 16 4 L 18 4 L 18 16 L 7 16 C 6.648438 16 6.316406 16.074219 6 16.1875 L 6 5 C 6 4.433594 6.433594 4 7 4 Z M 12 4 L 14 4 L 14 8.277344 L 13 7.578125 L 12 8.277344 Z M 12 4 "/>
                                    </svg>
                                    <span className='d-inline d-sm-none mob-link'>View Bookmarks</span>
                                </Link>
                                {this.state.showCalendar && <Calendar value={simpleDate.simpleDateToDate(this.props.state.today)} onChange={this.props.handleClickCalendar}/>}
                            </li>
                            <li className="nav-item">
                                <Link to="/export" className="btn export-button" title="Export Notes">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style={{ fill: '#fff' }}>
                                        <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
                                            <path d="M33.71,52.24,52.49,33.52a2.67,2.67,0,0,0-3.76-3.78L34.61,43.81V2.67a2.67,2.67,0,1,0-5.33,0V44L15.17,29.75a2.67,2.67,0,0,0-3.79,3.76L29.93,52.23a2.67,2.67,0,0,0,3.78,0Z"/>
                                            <path d="M56,64H8a8,8,0,0,1-8-8V37.33a2.67,2.67,0,0,1,5.33,0V56A2.67,2.67,0,0,0,8,58.67H56A2.67,2.67,0,0,0,58.67,56V37.33a2.67,2.67,0,0,1,5.33,0V56A8,8,0,0,1,56,64Z"/>
                                            </g></g>
                                    </svg>
                                    <span className='d-inline d-sm-none mob-link'>Export Notes</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <button className="btn user-button" onClick={this.handleUserClick} title="Account">
                                <svg viewBox="8 2 8 20">
                                    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                        <path d="M20.75,6.99 C20.61,6.44 20.06,6.12 19.51,6.24 C17.13,6.77 14.48,7 12,7 C9.52,7 6.87,6.77 4.49,6.24 C3.94,6.12 3.39,6.44 3.25,6.99 C3.11,7.55 3.45,8.12 4,8.25 C5.61,8.61 7.35,8.86 9,9 L9,21 C9,21.55 9.45,22 10,22 C10.55,22 11,21.55 11,21 L11,16 L13,16 L13,21 C13,21.55 13.45,22 14,22 C14.55,22 15,21.55 15,21 L15,9 C16.65,8.86 18.39,8.61 19.99,8.25 C20.55,8.12 20.89,7.55 20.75,6.99 Z M12,6 C13.1,6 14,5.1 14,4 C14,2.9 13.1,2 12,2 C10.9,2 10,2.9 10,4 C10,5.1 10.9,6 12,6 Z" id="Icon-Color"></path>
                                    </g>
                                    </svg>
                                    <span className='d-inline d-sm-none mob-link'>Your Account</span>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div className="dropdown-header">{this.props.state.email}</div>
                                    <Link to="password-change" className="dropdown-item">Change Password</Link>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#" onClick={this.props.logout}>Logout
                                        <svg className="float-right" viewBox="0 0 16 16" version="1.1" aria-hidden="true">
                                        <path fillRule="evenodd" d="M12 9V7H8V5h4V3l4 3-4 3zm-2 3H6V3L2 1h8v3h1V1c0-.55-.45-1-1-1H1C.45 0 0 .45 0 1v11.38c0 .39.22.73.55.91L6 16.01V13h4c.55 0 1-.45 1-1V8h-1v4z">
                                        </path>
                                    </svg></a>
                                </div>
                            </li>
                            </>
                        }
                    </ul>
                </div>
            </nav>
        )
    }

    handleClickCalendarLink = () => {
        const calButton = document.getElementsByClassName('calendar-button')[0];
        
        if (!this.state.showCalendar) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideCalendarClick, false);
            calButton.classList.add("menu-open");
        } else {
            document.removeEventListener('click', this.handleOutsideCalendarClick, false);
            calButton.classList.remove("menu-open");
        }

        this.setState({ showCalendar: !this.state.showCalendar });
    }

    handleOutsideCalendarClick = (e) => {
        // Hacky toString to avoid other event targets of different types
        if (e.target.className.toString().indexOf('calendar') === -1 && e.target.localName !== 'abbr') {
            this.handleClickCalendarLink();
        }
    }

    handleUserClick = (e) => {
        const menu = document.getElementsByClassName('dropdown-menu')[0];
        const userButton = document.getElementsByClassName('user-button')[0];

        if (menu && userButton) {
            if (!menu.style.display || menu.style.display === "none") {
                userButton.classList.add("menu-open");
                menu.style.display = "block";
                document.addEventListener('click', this.handleOutsideUserMenuClick, false);

            } else {
                menu.style.display = "none";
                userButton.classList.remove("menu-open");
                document.removeEventListener('click', this.handleOutsideUserMenuClick, false);
            }
        }
    }

    handleOutsideUserMenuClick = (e) => {
        // Hacky toString here in case we have a different type of target
        if (e.target.className.toString().indexOf('dropdown-menu') === -1
            && e.target.className.toString().indexOf('dropdown-divider') === -1) {
            this.handleUserClick();
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const changeEvent = new Event('input', { bubbles: true });
        this.input.dispatchEvent(changeEvent);
    }

    mobileNavToggle = () => {
        const navbar = document.getElementsByClassName('navbar-collapse')[0];
        if (navbar.classList.contains('show')) {
            navbar.classList.remove('show');
        } else {
            navbar.classList.add('show');
        }
    }
}

export default withRouter(Nav);